import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class extends Model {
  @belongsTo('view', {
    polymorphic: true,
    async: true,
    inverse: 'configuredWidgets',
  })
  view;

  @hasMany('configured-component', {
    inverse: 'configuredWidget',
    async: true,
  })
  configuredComponents;

  @attr('string')
  widgetType;
}
