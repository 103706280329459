import { hasMany } from '@ember-data/model';
import ViewModel from 'client-app-omnivise-web/models/view';

export default class extends ViewModel {
  @hasMany('configured-widget', {
    inverse: 'view',
    as: 'view',
    async: true,
  })
  configuredWidgets;
}
