import Model, { belongsTo, attr } from '@ember-data/model';

export default class extends Model {
  @attr('string') embedUrl;

  @belongsTo('configured-widget', {
    inverse: 'configuredComponents',
    async: true,
  })
  configuredWidget;

  @belongsTo('component-descriptor', {
    inverse: 'configuredComponents',
    async: true,
  })
  componentDescriptor;

  @attr('string') ccType;
}
