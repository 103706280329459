import Model, { belongsTo, hasMany, attr } from '@ember-data/model';

export default class extends Model {
  @attr('string') title;
  @belongsTo('module', {
    inverse: 'views',
    async: true,
    as: 'view',
  })
  module;

  // NOTE: this must be defined here, even if we don't use it in external views
  @hasMany('configured-widget', {
    inverse: 'view',
    as: null,
    async: true,
  })
  configuredWidgets;
}
